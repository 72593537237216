import { PageProps } from 'gatsby';
import React from 'react';
import { ContentfulBlog } from '../../../generated/graphql-types';
import { Container } from '../core/layout/Layout';
import Heading from '../core/typography/Heading';
import BackgroundImage from 'gatsby-background-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../../contentful/render-options';
import SEO from '../core/meta/SEO';
import Button from '../core/buttons/Button';
import { GatsbyImage } from 'gatsby-plugin-image';
import CollectEmail from '../partials/CollectEmail/CollectEmail';
import useDatalayer from '../../../hooks/use-datalayer';
import { useAnalytics } from '../../analytics/AnalyticsProvider';
import { convertToBgImage } from 'gbimage-bridge';

const SingleBlog = ({
  pageContext: { pageData },
}: PageProps<unknown, { pageData: Partial<ContentfulBlog> }>) => {
  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  const ctaImage = pageData.ctaImage
    ? convertToBgImage(pageData.ctaImage.gatsbyImageData)
    : null;

  return (
    <>
      <SEO
        title={pageData.metaTitle || pageData.title}
        description={pageData.metaDescription}
        hidePageSuffix
        metaImage={pageData.image}
        url={`/blog/${pageData.slug}`}
      />
      <div className="pb-20">
        <section>
          {pageData.image && (
            <BackgroundImage
              {...convertToBgImage(pageData.image.gatsbyImageData)}
            >
              <div className="bg-black bg-opacity-60 pt-16 pb-8">
                <Container>
                  <Heading
                    variant="h1"
                    className="text-center mb-0"
                    style={{ color: '#ffffff' }}
                  >
                    {pageData.title}
                  </Heading>
                  <div className="flex justify-center items-center pt-5">
                    <svg
                      className="text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      height={24}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <p className="text-center text-white ml-2">
                      {pageData.date}
                    </p>
                  </div>
                </Container>
              </div>
            </BackgroundImage>
          )}
          <Container className="pt-10">
            {/* Gatsby contentful source not handling types properly,
            some types required some not. This ignore is not dangerous.
        */}
            {/* @ts-ignore */}
            {renderRichText(pageData.content, renderOptions)}
          </Container>
          {pageData.bodyCtaUrl && pageData.bodyCtaText && (
            <div className="flex justify-center mb-16 z-10 relative">
              <a href={pageData.bodyCtaUrl}>
                <Button
                  variant="filled"
                  color="primary"
                  onClick={() => {
                    datalayer.triggerContentfulDatalayerAction(
                      pageData.bodyCtaDatalayerEvent
                    );
                    if (pageData.bodyCtaEvent) {
                      analytics.submitEvent(pageData.bodyCtaEvent as any, {
                        location: pageData.bodyCtaEventLocation as any,
                      });
                    }
                  }}
                >
                  {pageData.bodyCtaText}
                </Button>
              </a>
            </div>
          )}
          {pageData.spacerImage && (
            <Container>
              <GatsbyImage
                image={pageData.spacerImage.gatsbyImageData}
                alt="spacer image"
                className="md:w-1/2 lg:w-1/2 mx-auto"
              />
            </Container>
          )}
          {pageData.content2 && (
            <Container className="pt-10">
              {/* Gatsby contentful source not handling types properly,
            some types required some not. This ignore is not dangerous.
        */}
              {/* @ts-ignore */}
              {renderRichText(pageData.content2, renderOptions)}
            </Container>
          )}
        </section>
        {!!pageData.ctaText && !!pageData.ctaImage && !!pageData.ctaUrl ? (
          <section>
            <Container className="py-12">
              <div className="flex flex-wrap -mx-4 items-center">
                <div className="w-full lg:w-2/5 p-4 mb-4 lg:pb-10">
                  {/* Gatsby contentful source not handling types properly,
                  some types required some not. This ignore is not dangerous.
              */}
                  {pageData.ctaText &&
                    // @ts-ignore
                    renderRichText(pageData.ctaText, renderOptions)}

                  {pageData.ctaUrl && pageData.ctaButtonText && (
                    <div className="pt-4">
                      <a
                        href={pageData.ctaUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button variant="filled" color="primary">
                          {pageData.ctaButtonText}
                        </Button>
                      </a>
                    </div>
                  )}

                  {(!pageData.ctaUrl || !pageData.ctaButtonText) && (
                    <div className="pt-3">
                      <CollectEmail />
                    </div>
                  )}
                </div>
                <div className="w-full lg:w-3/5 p-10 rounded-lg">
                  {pageData?.ctaImage && ctaImage && (
                    <div className="rounded-lg overflow-hidden">
                      <BackgroundImage alt="laptop" {...ctaImage}>
                        <div style={{ height: '300px' }}></div>
                      </BackgroundImage>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </section>
        ) : null}
      </div>
    </>
  );
};

export default SingleBlog;
